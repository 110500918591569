

.homeback::after{
    content: '';
    background: url('../images/shadow.svg')no-repeat;
    position: absolute;
    height:500px;
    width:450px;
    left: -80px;
z-index: 1;
  
    background-size: contain;
}

.myImg::before{
    content: '';
    background: url('../images/photobackblur.svg') no-repeat 50%;
    position: absolute;
    right: 125px;
    top: 100px;
    height: 500px;
    width: 500px;
    background-size: contain;
    z-index: 1;
}
@media screen and (max-width: 768px) {
    

    .homeback::after{
        content: '';
        background: url('../images/shadow.svg')no-repeat;
        position: absolute;
        height: 480px;
    width: 260px;
    left: -80px;
    z-index: 1;
      
        background-size: contain;
    }
    
    .myImg::before{
        content: '';
        background: url('../images/photobackblur.svg') no-repeat 50%;
        position: absolute;
       left: -14px;
        top: 2px;
        height: 473px;
        width: 195px;
        background-size: contain;
        z-index: 1;
    }

}


