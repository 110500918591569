@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
body {
  scroll-behavior: smooth;
  background-color: #0f172a;
}
.sec-bg-color {
  background-color: #0f172a;
}

.active{
  color: #1cc2e7;
}
.lineImg::after {
  content: "";
  height: 1px;
  width: 100px;
  background-color: #5f6679;
  margin: 0;
  display: inline-block;
  margin-left: 15px;
}
.lineImg::before {
  content: "";
  height: 1px;
  width: 100px;
  background-color: #5f6679;
  margin: 0;
  display: inline-block;
  margin-right: 15px;
}
.gradient-text {
  background-image: linear-gradient(to right, #0daae7, #0bccd3);
  -webkit-background-clip: text;
  color: transparent;
}
.btn {
  border-radius: 6px;
  padding: 10px 16px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  text-decoration: none;
  border: 0;
}

.btn-hover {
  background-image: linear-gradient(90deg, #0ea5ea, #0bd1d1 51%, #0ea5ea);
  background-size: 300% 100%;
  border-radius: 10px;
  color: white;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 4px 20px 0px rgba(65, 189, 234, 0.75);
}

.btn-hover:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}
.timeLine::before {
  content: "";
  /* height: 450px; */
  height: calc(80% + 10px);
  width: 3px;
  position: absolute;
  left: -20px;
  background: #a8b2d1;
  border-radius: 30px;
}
.timeLine-item::before {
  content: "";
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: absolute;
  left: -29px;
  background: linear-gradient(to right, #0daae7, #0bccd3);
}
.hover-neon:hover {
  background: linear-gradient(rgb(19, 28, 49), rgb(19, 28, 49)) padding-box
      padding-box,
    linear-gradient(90deg, rgb(14, 165, 234), rgb(11, 209, 209)) border-box
      border-box;
  border-radius: 16px;
  border: 1px solid transparent;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  transform: translateY(-2px);
  transition: all 0.25s;
}

.hover-neon-light:hover {
  background: linear-gradient(#e8edf5, #e8edf5) padding-box padding-box,
    linear-gradient(90deg, rgb(14, 165, 234), rgb(11, 209, 209)) border-box
      border-box;
  border-radius: 16px;
  border: 1px solid transparent;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  transform: translateY(-2px);
  transition: all 0.25s;
}

.active-input:focus {
  background: linear-gradient(rgb(19, 28, 49), rgb(19, 28, 49)) padding-box
      padding-box,
    linear-gradient(90deg, rgb(14, 165, 234), rgb(11, 209, 209)) border-box
      border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  transition: all 0.25s;
}
.active-input-light:focus {
  background: linear-gradient(#e8edf5, #e8edf5) padding-box padding-box,
    linear-gradient(90deg, rgb(14, 165, 234), rgb(11, 209, 209)) border-box
      border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: rgba(11, 209, 209, 0.2) 0px 3px 20px;
  transition: all 0.25s;
}
.font-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.font-2 {
  font-family: "Ubuntu", sans-serif;
}

.sticky-bar-ani {
  animation: 0.7s fadeInDown ease-in-out 0s 1 normal none running;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  left: 0;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #222f43;
}

.sticky-bar-ani-light {
  animation: fadeInDown 0.7s ease-in-out 0s 1 normal none running;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  left: 0;
  transition: all 0.3s ease 0s;
  border-bottom: 1px solid #c2d4ee;
}
@media screen and (max-width: 768px) {
  .lineImg::after {
    width: 100%;
    display: none;
  }
  .lineImg::before {
    width: 100%;
    display: none;
  }
  
}

input:-webkit-autofill {
  background-color: transparent !important
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #0daae7, #0bccd3);
  border-radius: 5px;
}
.scrollbar-dark::-webkit-scrollbar-track {
  background-color: #0f172a;
}
.scrollbar-light::-webkit-scrollbar-track {
  background-color: #f9fbff;
}
