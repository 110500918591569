.footer-links:hover{
    transition: all 0.5s ;
color: #0ea5ea;
transform: translateX(2px);
}
.footer-social-links:hover{
    transition: all 0.25s ;
    background-image: linear-gradient(90deg, #0ea5ea, #0bd1d1 51%, #0ea5ea);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: translateY(-2px);
}

.footer-copyright-div::after{
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
}